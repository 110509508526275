import { useEffect } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { AiFillEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
createTheme('light', {
    text: {
        primary: '#268bd2',
        secondary: '#2aa198',
    },
    background: {
        default: '#002b36',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#073642',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');

const columns = [
  
    {
        name: 'Name',
        selector: row => row.name[0].firstname + ' ' + row.name[0].lastname,
        wrap: true,
    },
    {
        name: 'Session ID',
        selector: row => row.session_id,
        wrap: true,
    },
    {
        name: 'No. of Notes',
        selector: row => row.count,
    },
    {
        name: 'View',
        selector:  (row) => {
            return (
                <Link to={'/patient-details/consultations/details'} state={row.session_id } >
                    <AiFillEye className="text-primary100 text-2xl" />
                </Link>
           )
        }

    },
   
];


export default function CTable({ data }) {
    useEffect(() => {

    }, []);
    return (
      
        <DataTable title="Consultations" columns={columns} data={data} pagination />
    );
};