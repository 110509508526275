import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NotFoundScreen from "./components/NotFoundScreen";
import Notes from "./components/Patient/Notes";
import PatientDetails from "./components/Patient/PatientDetails";
import Payment from "./components/Payment/Payment";
import Visits from "./components/Visit/Visit";
import BookedAppointment from "./screens/appointment/BookedAppointment";
import FirstAppointment from "./screens/appointment/FirstAppointment";
import Online from "./screens/appointment/Online";
import Patient from "./screens/appointment/Patient";
import CreatePaaswordScreen from "./screens/CreatePaaswordScreen";
import FirstLoginScreen from "./screens/FirstLoginScreen";
import HomeDashboardScreen from "./screens/HomeDashboardScreen";
import LoginScreen from "./screens/LoginScreen";
import MarkScreen from "./screens/MarkScreen";
import NotificationScreen from "./screens/notification/NotificationScreen";
import PasswordCorrectScreen from "./screens/PasswordCorrectScreen";
import RegisterScreen from "./screens/RegisterScreen";
import WelcomeScreen from "./screens/WelcomeScreen";
import Consultations from "./components/Patient/Consultations";
import DInvestigaion from "./components/Patient/DInvestigaion";
import Investigation from "./components/Patient/Investigation";
import PrescriptionScreen from "./components/Patient/Prescriptions";
import DiagnosisScreen from "./components/Patient/Diagnosis";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<WelcomeScreen />} />
      <Route path="/change-password" element={<RegisterScreen />} />
      <Route path="/login" element={<FirstLoginScreen />} />
      <Route path="/success" element={<MarkScreen />} />
      <Route path="/createpassword" element={<CreatePaaswordScreen />} />
      <Route path="/passwordcorrect" element={<PasswordCorrectScreen />} />
      <Route path="/dashboard" element={<HomeDashboardScreen />} />
      <Route path="/first-appointment" element={<FirstAppointment />} />
      <Route path="/not-found" element={<NotFoundScreen />} />
      <Route path="*" element={<Navigate to="/not-found" />} />
      <Route path="/notification" element={<NotificationScreen />} />
      <Route path="/patient-record" element={<Patient />} />
      <Route path="/patient-details" element={<PatientDetails />} />
      <Route path="/recent-visits" element={<Visits />} />
      <Route path="/payment-history" element={<Payment />} />
      <Route path="/doctors-notes" element={<Notes />} />
      <Route path="/appointment-booked" element={<BookedAppointment />} />
      <Route path="/appointment-online" element={<Online />} />
      <Route path="/patient-details/consultations" element={<Consultations />} />
      <Route path="/patient-details/consultations/details" element={<Notes />} />
      <Route path="/patient-details/diagnostic_investigation" element={<DInvestigaion />} />
      <Route path="/patient-details/diagnostic_investigation/details" element={<Investigation />} />
      <Route path="/patient-details/prescriptions" element={<PrescriptionScreen />} />
      <Route path="/patient-details/diagnosis" element={<DiagnosisScreen />} />
    </Routes>
  );
}

export default Router;
