import React, { useState, useEffect } from 'react'
import TopBar from '../TopBar'
import DITable from './DITable'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInvInvestigation } from '../../redux/actions/DInvestigation';

export default function DInvestigaion() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, success, error, data } = useSelector(state => state.getInvInvestigation);
    const [Investigation, setInvInvestigation] = useState();
    

    let nt = Investigation?.filter((obj, index, self) =>
        index === self.findIndex((t) => (
            t.session_id === obj.session_id
        ))
    );
    
    useEffect(() => {
        if (!success) {
            dispatch(getInvInvestigation(localStorage.getItem('patient_id')));
        }
        setInvInvestigation(data?.investigation)
    }, [success]);

  return (
      <div className="h-screen">
          <TopBar title={"Diagnostic Investigations"} url={-1} />
          <div className='mt-8'>
              <DITable data={nt} />
          </div>
      </div>
  )
}
