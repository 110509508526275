import React, { useEffect, useState } from 'react'
import TopBar from '../TopBar'
import { useNavigate } from 'react-router-dom'
import CTable from './CTable';
import { useDispatch, useSelector } from 'react-redux';
import { getNotes } from '../../redux/actions/notes';

export default function Consultations() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, success, error, data } = useSelector(state => state.getNotes);
    const [notes, setNotes] = useState();
    

    let nt = notes?.filter((obj, index, self) =>
        index === self.findIndex((t) => (
            t.session_id === obj.session_id
        ))
    );
    
    useEffect(() => {
        if (!success) {
            dispatch(getNotes(localStorage.getItem('patient_id')));
        }
        setNotes(data?.doctors_note)
    }, [success]);

  return (
      <div className="h-screen">
          <TopBar title={"Consultations"} url={-1} />
          <div className='mt-8 justify-center'>
              <CTable data={nt} />
          </div>
        </div>
  )
}
