import React from "react";
import { AiFillClockCircle, AiFillEye } from "react-icons/ai";
import { BsFillCalendar2DateFill, BsGenderFemale } from "react-icons/bs";
import { MdPhoneInTalk } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

export default function PatientCard({ pics, name, title, phone, gender, id }) {
  const location = useLocation();
  // const phone = JSON.parse(localStorage.getItem("hospital_phone"))
  return (
    <div className="border-2 shadow-xl shadow-black-600 mt-4 mx-3 rounded-lg p-2">
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center justify-center rounded-full h-[75px] w-[75px] bg-blue-100">
          <img src={pics} height="70px" width="70px" />
        </div>
        <div className="ml-3 w-72">
          <p className="text-[18px] font-bold">{name}</p>
          <p className="text-ash100 mt-1 font-bold">{title}</p>
        </div>
        <div className="bg-green-100 rounded-full p-2 flex justify-between items-center mr-2">
         
            <Link to={'/patient-details'} state={id} >
            <AiFillEye className="text-primary100 text-4xl" />
            </Link>
        
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex items-center w-40 bg-green-50 py-2">
          <span className="ml-3">
            <MdPhoneInTalk className="text-primary100" />
          </span>
          <span className="mx-3 font-bold">{phone}</span>
        </div>
        <div className="flex w-40 items-center bg-green-50 py-2">
          <span className="ml-3">
            <BsGenderFemale className="text-primary100" />
          </span>
          <span className="mx-3 font-bold">{gender}</span>
        </div>
      </div>
    </div>
  );
}
