import {
  CREATE_PASSWORD_REQUEST,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAIL,
  CREATE_PASSWORD_RESET,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_RESET
} from "../constants/createPasswordConstants.js";

const initialState = {
  loading: false,
  success: false,
  error: null,
  data: null
}

const createPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PASSWORD_REQUEST:
      return { loading: true };
    case CREATE_PASSWORD_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case CREATE_PASSWORD_FAIL:
      return { loading: false, success: false, error: action.payload };
    case CREATE_PASSWORD_RESET:
      return {};
    default:
      return state;
  }
};
const change_password = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return { loading: true , success: false};
    case CHANGE_PASSWORD_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case CHANGE_PASSWORD_FAIL:
      return { loading: false, success: false, error: action.payload };
    case CHANGE_PASSWORD_RESET:
      return {state:initialState};
    default:
      return state;
  }
};

export { createPasswordReducer, change_password };
