import {GET_VISITS, GET_VISITS_SUCCESS, GET_VISITS_FAIL, GET_CLINIC_NAME, GET_CLINIC_NAME_SUCCESS,GET_CLINIC_NAME_FAIL} from "../constants/visit";
import axios from "axios";
import { baseUrl } from "./baseUrl";


export const visits_summary = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_VISITS,
        });
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('md_btn_mi_sas'))),
            },
        };
        const { data } = await axios.get(
            `${baseUrl}/visits_summary`,
            config
        );

        dispatch({
            type: GET_VISITS_SUCCESS,
            payload: data,
        });
    } catch (err) {
        let message =
            err.response && err.response.data.errors
                ? err.response.data.errors
                : err.message;
        dispatch({
            type: GET_VISITS_FAIL,
            payload: message,
        });

    }
}

export const get_clinic_name = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_CLINIC_NAME,
        });
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('md_btn_mi_sas'))),
            },
        };
        const { data } = await axios.get(
            `${baseUrl}/get_clinic_name`,
            config
        );

        dispatch({
            type: GET_CLINIC_NAME_SUCCESS,
            payload: data,
        });
    } catch (err) {
        let message =
            err.response && err.response.data.errors
                ? err.response.data.errors
                : err.message;
        dispatch({
            type: GET_CLINIC_NAME_FAIL,
            payload: message,
        });

    }
}
