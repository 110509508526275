import { combineReducers } from "redux";
import { createUserReducer, loginUserReducer, getOTpReducer, verifyOtpReducer, logoutUserReducer } from "./userReducers";
import { bookAppontmentReducer, getAppointmentReducer, getClinicsReducer, getDoctorsReducer } from "./appointmentReducer";
import { change_password, createPasswordReducer } from "./createPasswordReducer";
import { getPaymentReducer } from "./payment";
import { get_patient, search_patient_request } from "./patient";
import { get_clinic_name_reducer, visits_summary_reducer } from "./visit";
import { getNotes } from "./notes";
import { getInvInvestigation } from "./DInvestigation";
import { Prescriptions } from "./Prescriptions";
import { Diagnosis } from "./Diagnosis";

const rootReducer = combineReducers({
  createUser: createUserReducer,
  loginUser: loginUserReducer,
  bookAppontment: bookAppontmentReducer,
  createPassword: createPasswordReducer,
  getOtp: getOTpReducer,
  verifyOtp: verifyOtpReducer,
  getAppointmentAction: getAppointmentReducer,
  getDoctorsAction: getDoctorsReducer,
  getClinicsAction: getClinicsReducer,
  search_patient_request: search_patient_request,
  get_patient: get_patient,
  visits_summary_reducer: visits_summary_reducer,
  get_clinic_name: get_clinic_name_reducer,
  getPayment: getPaymentReducer,
  getNotes: getNotes,
  change_password: change_password,
  logOutUser: logoutUserReducer,
  getInvInvestigation: getInvInvestigation,
  Prescriptions: Prescriptions,
  Diagnosis: Diagnosis


});

export default rootReducer;
