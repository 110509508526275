import { SEARCH_PATIENT_REQUEST, SEARCH_PATIENT_SUCCESS, SEARCH_PATIENT_FAIL, GET_PATIENT_REQUEST, GET_PATIENT_SUCCESS, GET_PATIENT_FAIL, GET_PATIENT_RESET } from "../constants/patient";

const initialState = {
    loading: false,
    success: false,
    error: null,
    data: null
}

const search_patient_request = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_PATIENT_REQUEST:
            return { ...state, loading: true, success: false };
        case SEARCH_PATIENT_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload };
        case SEARCH_PATIENT_FAIL:
            return { ...state, loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}
const get_patient = (state = initialState, action) => {
    switch (action.type) {
        case GET_PATIENT_REQUEST:
            return { ...state, loading: true, success: false };
        case GET_PATIENT_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload };
        case GET_PATIENT_FAIL:
            return { ...state, loading: false, success: false, error: action.payload };
        case GET_PATIENT_RESET:
            return {};
        default:
            return state;
    }
}


export { search_patient_request, get_patient }
