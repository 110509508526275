import { GET_PRESCRIPTIONS_FAIL, GET_PRESCRIPTIONS_REQUEST, GET_PRESCRIPTIONS_SUCCESS } from "../constants/Prescriptions";


const initialState = {
    loading: false,
    success: false,
    error: null,
    data: null
}

const Prescriptions = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRESCRIPTIONS_REQUEST:
            return { ...state, loading: true };
        case GET_PRESCRIPTIONS_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload };
        case GET_PRESCRIPTIONS_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}


export { Prescriptions }
