import { GET_VISITS, GET_VISITS_SUCCESS, GET_VISITS_FAIL, GET_CLINIC_NAME, GET_CLINIC_NAME_SUCCESS, GET_CLINIC_NAME_FAIL } from "../constants/visit";

const initialState = {
    loading: false,
    success: false,
    error: null,
    data: null
}

const visits_summary_reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VISITS:
            return { ...state, loading: true };
        case GET_VISITS_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload };
        case GET_VISITS_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}
const get_clinic_name_reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLINIC_NAME:
            return { ...state, loading: true };
        case GET_CLINIC_NAME_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload };
        case GET_CLINIC_NAME_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}


export { visits_summary_reducer, get_clinic_name_reducer }
