import React from 'react'
import TopBar from '../TopBar'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import DGTable from './DGTable';
import { Diagnosis } from '../../redux/actions/Diagnosis';
import Loading from '../Loading';


export default function DiagnosisScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, success, error, data } = useSelector(state => state.Diagnosis);
    const [state, setState] = useState([]);

    useEffect(() => {
        dispatch(Diagnosis(localStorage.getItem('patient_id')));
    //    setTimeout(() => {
           setState(data?.diagnosis);
    //    }, 500);
    }, [success]);

    return (
        <div className="h-screen">
            <TopBar title={"Diagnosis"} url={-1} />
            <div className='mt-8 mx-2'>
                {
                    loading ? <Loading /> : state?.length < 1 ? <div className="flex justify-center"><p className='self-center' >There are no records to display</p></div> : <DGTable title={state?.length > 0 ? state?.[0].firstname + ' ' + state?.[0].lastname : 'Diagnosis'} data={state} />
                }
              
            </div>
        </div>
    )
}
